import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Alerts } from './Alerts'
import { Colors } from 'shared/styles/Colors'
import { FetchError } from 'shared/components/FetchError'
import { Fonts } from 'shared/styles/Typography'
import { Fragments } from 'shared/constants/Fragments'
import { Header } from './Header/Header'
import { PoweredBy } from 'shared/components/PoweredBy'
import { setBakery } from 'shared/reducers/account'
import { SocialButtons } from 'shared/components/SocialButtons'
import { useBakeryRedirect } from 'web/hooks/useBakeryRedirect'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const BakeryPageLayout = ({ children, user }) => {
  const dispatch = useDispatch()
  const { slug, bakeryId } = useParams()
  const {
    backgroundColor: bgColor,
    primaryColor,
    dividerVariant,
    primaryFont,
    secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
  } = useThemeContext()
  const onCompleted = useBakeryRedirect()

  const listOuterRef = useRef()
  const listInnerRef = useRef()

  const [showScrollIndicator, setShowScrollIndicator] = useState()

  const { data, error, refetch } = useQuery(BAKERY_QUERY, {
    variables: { slug, id: bakeryId },
    onCompleted,
    skip: !slug && !bakeryId,
  })

  useEffect(() => {
    if (data?.bakery && data?.bakery?.status === 'active') dispatch(setBakery(data.bakery))
  }, [data, dispatch])

  const bakery = data?.bakery
  const { hasScrollIndicator = false } = children?.props || {}
  const hasPremium = (bakery?.user || user)?.subscriptionTier === 'Premium'

  const onScroll = () => {
    if (listOuterRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listOuterRef.current
      if (scrollTop + clientHeight >= 0.95 * scrollHeight) {
        setShowScrollIndicator(false)
      } else {
        setShowScrollIndicator(true)
      }
    }
  }

  useEffect(() => {
    // Hack around Intstagram browser dimensions bug by forcing a fake resize event
    if (navigator.userAgent.match(/instagram/i)) {
      window.dispatchEvent(new CustomEvent('resize'))
    }

    setShowScrollIndicator(!allElementsInViewport(listInnerRef.current))
  }, [])

  const allElementsInViewport = (el) => {
    let top = el.offsetTop
    let left = el.offsetLeft
    let width = el.offsetWidth
    let height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    )
  }

  const { isMdUp } = useResponsiveLayout()

  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  const backgroundRegExp = new RegExp(/category|order-request|products|review/)
  const hasDefaultBackground = backgroundRegExp.test(window.location.pathname)
  const backgroundColor = hasDefaultBackground ? Colors.white : bgColor
  const hasSocial = facebookHandle || instagramHandle || tiktokHandle

  return (
    <Container
      backgroundColor={dividerVariant > 0 && !hasDefaultBackground ? primaryColor : backgroundColor}
      ref={listOuterRef}
      onScroll={onScroll}
      primaryFont={primaryFont}
      secondaryFont={secondaryFont}
    >
      <Alerts />
      <Header bakery={bakery} />
      <div ref={listInnerRef}>{children}</div>

      {hasSocial ? (
        <SocialButtons
          theme={{ facebookHandle, instagramHandle, tiktokHandle, primaryColor }}
          bakeryName={bakery?.name}
        />
      ) : (
        !hasPremium && <PoweredBy customStyle={{ marginBottom: 0 }} />
      )}

      {hasScrollIndicator && !isMdUp && showScrollIndicator && (
        <ScrollIndicatorContainer>
          <ScrollIndicatorCircle>
            <FontAwesomeIcon icon={faChevronDown} color={Colors.grey75} />
          </ScrollIndicatorCircle>
        </ScrollIndicatorContainer>
      )}
    </Container>
  )
}

const Container = styled.div(({ backgroundColor, primaryFont, secondaryFont }) => {
  const primary = primaryFont.includes('Carlito')
    ? Fonts.DefaultPrimary
    : `${primaryFont}, ${Fonts.Fallback}`

  return {
    backgroundColor,
    fontFamily: secondaryFont,
    height: '100%',

    'h1, h2, h3,h4, h5, h6': {
      fontFamily: primary,
    },
  }
})

const ScrollIndicatorContainer = styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
`

const ScrollIndicatorCircle = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
`

const BAKERY_QUERY = gql`
  ${Fragments.CustomSection}

  query getBakeryPageLayout($slug: String, $id: ID) {
    bakery(slug: $slug, id: $id) {
      bio
      blockedDates
      blockTimeUnit
      blockTimeValue
      capabilitiesAndPricing
      enableAnnouncementBanner
      futureBlockMonths
      id
      leadTimeBlockEnabled
      leadTimeUnit
      leadTimeValue
      minimumOrderInCents
      name
      ordersPaused
      requiredDepositPercentage
      slug
      status
      announcementBanner {
        backgroundColor
        fontColor
        message
      }
      bakedGoods: offerings(offeringType: bakedGood, selected: true, hidden: false) {
        id
        name
      }
      categories {
        id
        slug
        name
        default
        offerings(hidden: false, selected: true) {
          id
        }
      }
      customSections {
        ...CustomSectionFragment
      }
      faqs {
        id
      }
      galleryImage {
        id
        thumbnailUrl
        updatedAt
      }
      location {
        id
        name
        countryCode
        region {
          country {
            name
          }
          name
        }
      }
      menuItems: offerings(offeringType: menuItem, selected: true, hidden: false) {
        id
      }
      presaleItems: offerings(offeringType: presaleItem, selected: true, hidden: false) {
        id
      }
      reviewSummary {
        average
        count
      }
      user {
        id
        name
        email
        contactMethod
        formattedMobileNumber
        subscriptionTier
        avatar {
          largeUrl
          smallUrl
        }
      }
    }
  }
`
