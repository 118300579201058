import { gql } from '@apollo/client'

const ORDER_REQUEST_FRAGMENT = gql`
  fragment OrderDuringBuild on Order {
    id
    completionDate
    sentAt
    otherDetails
    state
    addressType
    orderNumber
    instantCheckoutAllowed
    orderItems {
      categoryId
      offeringId
      quantity
      unit
      spec
      offering {
        id
        name
        priceCents
        dueDateDisabled
        enableInspirationPhotos
        enableInstantCheckout
      }
    }
    orderAdjustments {
      amountCents
      id
      adjustment {
        adjustmentCategory
        adjustmentType
        amountCents
        id
        isActive
        name
        percent
        code
      }
    }
    inspirationPhotos {
      id
      key
      thumbnailUrl
    }
    customerName
    customer {
      id
    }
    customQuestions {
      id
      question
      answer
      answers
    }
    totalAmountCents
    subtotalAmountCents
    sentAt
    bakery {
      currency {
        flagUrl
        id
        name
        symbol
      }
      paymentMethods(selected: true) {
        id
        displayType
        username
        usernameType
        name
      }
      user {
        countryCode
        avatar {
          largeUrl
        }
      }
      hasDiscountCodes
      id
      name
      slug
      blockedDates
      enableOrderSpecifications
      enableHalfDozens
      instantCheckoutMethod
      status
      selectedPresaleItems: offerings(offeringType: presaleItem, selected: true, hidden: false) {
        id
        allowHalfDozen
        allowMaxQuantity
        name
        image
        images {
          id
          key
          thumbnailUrl
          fullUrl
        }
        priceCents
        minQuantity
        maxQuantity
        dozenOnly
        defaultUnit
        priceType
        stock
        description
        enableInstantCheckout
        category {
          id
          name
          position
        }
      }
      selectedBakedGoods: offerings(offeringType: bakedGood, selected: true) {
        id
        allowHalfDozen
        allowMaxQuantity
        name
        minQuantity
        maxQuantity
        dozenOnly
        defaultUnit
        stock
      }
      selectedMenuItems: offerings(offeringType: menuItem, selected: true, hidden: false) {
        id
        allowHalfDozen
        allowMaxQuantity
        name
        image
        images {
          id
          key
          thumbnailUrl
          fullUrl
        }
        priceCents
        minQuantity
        maxQuantity
        dozenOnly
        defaultUnit
        priceType
        stock
        description
        enableInstantCheckout
        category {
          id
          name
          position
        }
      }
      leadTimeUnit
      leadTimeValue
      leadTimeBlockEnabled
      futureBlockMonths
      customQuestions {
        id
        question
        required
        choices
        multiSelect
        offerings {
          id
        }
        categories {
          id
        }
      }
      termsAndConditions
    }
  }
`
export const ORDER_DETAILS_FRAGMENT = gql`
  fragment OrderDetailsFragment on Order {
    id
    addressType
    attachment {
      id
      fullUrl
      filename
    }
    completionDate
    customerName
    state
    sentAt
    depositDue
    balanceDue
    otherDetails
    orderNumber
    subtotalAmountCents
    totalAmountCents
    depositAmountCents
    finalPaymentAmountCents
    pickupAddress
    bakerOrderDetails
    import
    permittedStates
    customerFacingFee
    allowBakesyPayments
    allowOtherPayments
    totalTipCents
    isInstantCheckout
    depositInvoice {
      id
      amountInCents
      feeAmountInCents
      totalDueInCents
      note
      paidAt
      invoiceType
      stripeChargeId
      viewedAt
      sentAt
      tipCents
      paymentMethod {
        id
        displayType
        username
        usernameType
        selected
        type
        name
      }
    }
    finalInvoice {
      id
      amountInCents
      feeAmountInCents
      totalDueInCents
      note
      paidAt
      invoiceType
      stripeChargeId
      viewedAt
      sentAt
      tipCents
      paymentMethod {
        id
        displayType
        username
        usernameType
        selected
        type
        name
      }
    }
    finalInvoicePreview {
      amountInCents
      feeAmountInCents
      totalDueInCents
    }
    customer {
      id
      name
      email
      formattedMobileNumber
      mobileNumber
      contactOnly
    }
    orderAdjustments {
      id
      amountCents
      adjustment {
        adjustmentCategory
        adjustmentType
        amountCents
        id
        isActive
        name
        percent
        code
      }
    }
    bakery {
      collectTips
      currency {
        flagUrl
        id
        name
        symbol
      }
      instantCheckoutMethod
      paymentMethods(selected: true) {
        id
        displayType
        username
        usernameType
        name
      }
      name
      requiredDepositPercentage
      blockedDates
      slug
      dailyOrderLimit
      bakedGoods: offerings(offeringType: bakedGood, selected: true) {
        id
        name
      }
      user {
        id
        email
        mobileNumber
        countryCode
        subscriptionTier
        avatar {
          smallUrl
        }
      }
      customQuestions {
        id
        question
        required
        choices
        multiSelect
      }
    }
    review {
      rating
      comments
    }
    orderItems {
      id
      quantity
      unit
      spec
      offering {
        id
        name
        priceCents
        priceType
        enableInstantCheckout
        stock
      }
    }
    inspirationPhotos {
      id
      key
      thumbnailUrl
      fullUrl
    }
    customQuestions {
      id
      question
      answer
      answers
    }
  }
`

export const GET_ORDER = gql`
  ${ORDER_REQUEST_FRAGMENT}

  query getOrder($id: ID!) {
    order(id: $id) {
      ...OrderDuringBuild
    }
  }
`

export const ORDER_LIST_QUERY = gql`
  query OrderList($state: [OrderState!], $scope: OrderQueryScope) {
    ordersReceived(state: $state, scope: $scope) {
      results {
        id
        completionDate
        state
        import
        bakerViewed
        depositAmountCents
        totalAmountCents
        subtotalAmountCents
        customerName
        totalTipCents
        isInstantCheckout
        orderNumber
        customer {
          name
        }
        depositInvoice {
          id
          amountInCents
          totalDueInCents
          viewedAt
          paidAt
        }
        finalInvoice {
          id
          viewedAt
          paidAt
        }
        orderItems {
          offering {
            name
          }
        }
      }
    }
  }
`

export const ORDER_SEARCH = gql`
  query GetOrders(
    $q: String!
    $sortColumn: String!
    $sortDirection: String!
    $filterOptions: OrderFilterInput!
    $sendEmail: Boolean
  ) {
    orders(
      q: $q
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filterOptions: $filterOptions
      sendEmail: $sendEmail
    ) {
      ... on OrderConnection {
        nodes {
          id
          id
          completionDate
          state
          import
          bakerViewed
          depositAmountCents
          totalAmountCents
          subtotalAmountCents
          customerName
          isInstantCheckout
          customer {
            name
          }
          depositInvoice {
            id
            amountInCents
            totalDueInCents
            viewedAt
            paidAt
          }
          finalInvoice {
            id
            viewedAt
            paidAt
          }
          orderItems {
            offering {
              name
            }
          }
        }
      }
    }
  }
`

export const ORDER_DETAILS_QUERY = gql`
  ${ORDER_DETAILS_FRAGMENT}

  query OrderDetails($id: ID, $orderNumber: String) {
    order(id: $id, orderNumber: $orderNumber) {
      ...OrderDetailsFragment
    }
  }
`

export const CREATE_ORDER = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const IMPORT_ORDER = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation ImportOrder($input: ImportOrderInput!) {
    importOrder(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const REQUEST_ORDER = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation RequestOrder($input: RequestOrderInput!) {
    requestOrder(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_PAYMENT_DETAILS = gql`
  mutation UpdateOrderPaymentDetails($input: UpdateOrderPaymentDetailsInput!) {
    updateOrderPaymentDetails(input: $input) {
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_PRICE_ADJUSTMENTS = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation UpdateOrderPriceAdjustments($input: UpdateOrderPriceAdjustmentsInput!) {
    updateOrderPriceAdjustments(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const SHALLOW_UPDATE_ORDER_PRICE_ADJUSTMENTS = gql`
  mutation UpdateOrderPriceAdjustments($input: UpdateOrderPriceAdjustmentsInput!) {
    updateOrderPriceAdjustments(input: $input) {
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_DETAILS = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation UpdateOrderDetails($input: UpdateOrderDetailsInput!) {
    updateOrderDetails(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const SHALLOW_UPDATE_ORDER_DETAILS = gql`
  mutation UpdateOrderDetails($input: UpdateOrderDetailsInput!) {
    updateOrderDetails(input: $input) {
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_STATUS = gql`
  ${ORDER_DETAILS_FRAGMENT}

  mutation UpdateOrderStatus($input: UpdateOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      order {
        ...OrderDetailsFragment
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const SHALLOW_UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($input: UpdateOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_REQUEST = gql`
  ${ORDER_REQUEST_FRAGMENT}

  mutation UpdateOrderDetails($input: UpdateOrderDetailsInput!) {
    updateOrderDetails(input: $input) {
      order {
        ...OrderDuringBuild
      }
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const UPDATE_ORDER_ADDRESS = gql`
  mutation UpdateOrderAddress($input: UpdateOrderAddressInput!) {
    updateOrderAddress(input: $input) {
      errors {
        path
        messages
        fullMessages
      }
    }
  }
`

export const CANCEL_ORDER_QUERY = gql`
  mutation CancelDepositRequest($input: CancelDepositRequestInput!) {
    cancelDepositRequest(input: $input) {
      errors {
        messages
        path
      }
    }
  }
`
