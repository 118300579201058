import { useReducer } from 'react'

const initialState = {
  images: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setImages':
      return { images: action.payload }
    case 'replaceImage':
      const { id, image } = action.payload
      const images = [...state.images]
      const imgIdx = state.images.findIndex((img) => img.id === id)

      if (imgIdx < 0) return { images }

      images[imgIdx] = { ...image, thumbnailUrl: images[imgIdx].thumbnailUrl || image.thumbnailUrl }

      return { images }
    case 'removeImage':
      return { images: state.images.filter((i) => i.id !== action.payload) }
    case 'addImage':
      return { images: [action.payload, ...state.images] }
    default:
      throw new Error(`unknown action ${action.type}`)
  }
}

export const useGalleryReducer = () => {
  return useReducer(reducer, initialState)
}

export const setImages = (images) => ({ type: 'setImages', payload: images })
export const replaceImage = ({ id, image }) => ({ type: 'replaceImage', payload: { id, image } })
export const removeImage = (id) => ({ type: 'removeImage', payload: id })
export const addImage = (image) => ({ type: 'addImage', payload: image })
